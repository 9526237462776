@import "./app.less";

@border-radius: 9px;

.chat-container {
  position: absolute;
  width: 100%;
  height: calc(100% - @nav-bar-height);
  background-color: transparent;
  pointer-events: none;
  z-index: 100;
  overflow: hidden;

  &:has(> .dragging) {
    pointer-events: auto;
  }
}

.chat-window-container {
  pointer-events: auto;

  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }
}

.chat-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px 2px rgba(148, 149, 150, 0.3);
  background-color: white;
  border-radius: @border-radius;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;

  .header-bar {
    flex-grow: 0;
    height: 36px;
    line-height: 36px;
    padding: 0 6px 0 12px;
    box-sizing: content-box;
    background-color: #0e256e;
    color: white;
    font-size: 14px;
    border-top-left-radius: @border-radius;
    border-top-right-radius: @border-radius;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.incoming {
      animation: incoming-pulse 2s ease-in-out;
    }

    @keyframes incoming-pulse {
      0%,
      100% {
        background-color: #0e256e;
      }
      10%,
      50%,
      90% {
        background-color: #ff8f32;
      }
      30%,
      70% {
        background-color: #ffa94d;
      }
    }
  }

  .messages {
    flex-grow: 1;
    height: 200px;
    max-height: calc(100vh - 36px - 42px - @nav-bar-height);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px 12px;
    background-color: #f8f8f8;
    overflow-y: scroll;

    .message {
      margin: 4px 0;

      .content {
        display: flex;

        .text {
          padding: 6px 12px;
          border-radius: 12px;
          word-break: break-all;
          white-space: pre-wrap;
        }
      }

      .at {
        padding: 0 0;
        font-size: 0.85rem;
        color: #888;
      }

      &.other {
        align-self: flex-start;

        .content {
          flex-direction: row;

          .text {
            color: white;
            background-color: #1890ff;
            margin-right: 12px;

            a {
              color: white;
            }
          }
        }

        .at {
          text-align: left;
        }
      }
      &.me {
        align-self: flex-end;

        .content {
          flex-direction: row-reverse;

          .text {
            color: white;
            background-color: #666;
            margin-left: 12px;

            a {
              color: white;
            }
          }
        }

        .at {
          text-align: right;
        }
      }
    }
  }

  .footer-bar {
    flex-grow: 0;
    position: relative;
    border-top: solid 2px #ccc;
    bottom: 0;
    padding: 2px 4px;
    overflow: auto;

    .text {
      font-size: 1rem;
      width: 100%;
      min-height: 32px;
      padding-left: 8px;
      padding-right: 42px;
      border-width: 0;
      border-color: transparent;
      outline: none;
      resize: none;
      line-height: 1.5;

      &:focus,
      &:hover {
        border-color: transparent;
        box-shadow: none;
      }
    }

    .send {
      position: absolute;
      right: 8px;
      bottom: 2px;
    }
  }

  .close {
    padding: 6px 6px;
  }
}

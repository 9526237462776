.instant-disclosure {
  padding: 1.5rem;
  width: 600px;
  border: 1px solid lightgray;
  border-radius: 8px;

  .prompt,
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .note {
    margin-top: 0.5rem;
    color: gray;
  }

  .achievement {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 6rem;
  }

  .val {
    font-size: 1.5rem;
  }
}

@video-aspect-ratio: 16 / 9;
@background-color: rgb(24, 26, 27);

body:has(.outer-rc) {
  background-color: @background-color;
}

.outer-rc {
  width: 100svw;
  height: 100svh;
  object-fit: contain;
  background-color: @background-color;

  &.unavailable {
    padding-top: 48px;
    text-align: center;

    h1 {
      color: rgba(232, 230, 227, 0.85);
      font-size: 28px;
    }
  }

  .inner-rc {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .video-preview {
      --video-aspect-ratio: calc(16 / 9);
      width: ~"min(100svw, 100svh * var(--video-aspect-ratio))";
      height: ~"min(100svh, 100svw / var(--video-aspect-ratio))";
      aspect-ratio: @video-aspect-ratio;
      object-fit: contain;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100svw;
    height: 100svh;
    background-color: transparent;

    .status {
      --alpha: 0.7;
      position: absolute;
      top: 12px;
      left: 12px;
      color: ~"rgba(255, 255, 255, var(--alpha))";
      background-color: ~"rgb(64, 64, 64, var(--alpha))";
      padding: 6px 12px;
      border-radius: 18px;
      font-size: 12px;
    }

    .indicator {
      padding-right: 4px;
      &.connecting {
        color: orange;
      }
      &.streaming {
        color: green;
      }
      &.disconnected {
        color: red;
      }
    }

    .toggle-preview {
      position: absolute;
      top: 11px;
      right: 11px;
      border: 1px solid rgba(255, 255, 255, 0.85);
      color: rgba(255, 255, 255, 0.7);
      background-color: rgba(48, 48, 48, 0.85);
      padding: 6px 12px;
      border-radius: 18px;
      font-size: 12px;
      cursor: pointer;
    }

    &.hide {
      background-color: @background-color;
    }
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 16px;
    padding: 15%;
    color: rgba(232, 230, 227, 0.85);
  }
}

@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

@import "./app.less";
@import "./appLayout.less";
@import "./chat.less";
@import "./notepad.less";
@import "./video.less";
@import "./trialFinished.less";
@import "./notFound.less";
@import "./tc.less";
@import "./instantDisclosure.less";
@import "./rc.less";

html,
body,
#root {
  height: 100%;
}

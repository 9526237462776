@import "./app.less";

@border-radius: 9px;

@incoming-width: 320px;
@preview-width: 128px;
@video-aspect-ratio: 16 / 9;
@videos-aspect-ratio: 22.4 / 9;

.video-container {
  width: 100%;
  height: calc(100% - @nav-bar-height);
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  z-index: 100;
  overflow: hidden;

  &:has(> .dragging) {
    pointer-events: auto;
  }
}

.video-window-container {
  pointer-events: auto;

  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }
}

.video-window {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px 2px rgba(148, 149, 150, 0.3);
  background-color: white;
  border-radius: @border-radius;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  pointer-events: auto;

  .header-bar {
    height: 36px;
    line-height: 36px;
    padding: 0 12px;
    box-sizing: content-box;
    background-color: #0e256e;
    color: white;
    font-size: 14px;
    border-top-left-radius: @border-radius;
    border-top-right-radius: @border-radius;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .videos {
    display: flex;
    width: 100%;
    aspect-ratio: @videos-aspect-ratio;
  }

  #video-incoming {
    width: @incoming-width;
    aspect-ratio: @video-aspect-ratio;
    overflow-y: auto;
    position: relative;

    .placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #eee;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #666;

      .request-instruction {
        display: grid;
        grid-template-rows: 1fr 2.5rem 1fr;
        width: 100%;
        height: 100%;

        .head {
          grid-row-start: 2;
          width: 100%;
          text-align: center;
          line-height: 2.5;
        }

        .desc {
          font-size: 0.85rem;
          grid-row-start: 3;
          margin: 8px 16px;
          padding: 4px 8px;
          border-radius: 4px;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: left;

          a {
            margin: 0 0.2rem;
          }
        }
      }
    }

    .video,
    .audio {
      z-index: 1;
    }
  }

  .video-previews {
    width: @preview-width;
    overflow-y: auto;

    .video-preview {
      width: 100%;

      .video {
        aspect-ratio: @video-aspect-ratio;
      }

      .audio {
        height: 48px;
      }
    }
  }

  .controller-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 16px;
    border-top: solid 2px #ccc;

    .label {
      padding-right: 12px;
    }

    .controller {
      width: 196px;
    }

    .labeled-controller {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .controller,
      .label {
        display: inline-block;
      }
    }
  }
}

.react-draggable {
  .handle {
    cursor: grab;
  }

  &.react-draggable-dragging .handle {
    cursor: grabbing;
  }
}

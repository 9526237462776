#tc {
  margin-top: 8rem;

  font-size: 1.25rem;

  input {
    font-size: 1.25rem;
    font-stretch: expanded;
    font-family: "Fira Code", monospace;
    border: 1px solid lightgray;
    border-radius: 0.375rem;
    padding: 0 0.5rem;
    &.error {
      border-color: red;
    }
    &.w12 {
      width: 10.5rem;
    }
    &.w8 {
      width: 7.5rem;
    }
    &.w4 {
      width: 4.5rem;
    }
  }
  .uuid-input {
    span {
      color: gray;
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .space {
    height: 4rem
  }
}

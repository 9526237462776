@import "./app.less";

#app-layout {
  width: 100%;
  height: 100%;

  #header-nav {
    border-bottom: solid 1px #ccc;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.02);
    padding: ~"0 max((max(100%, 800px) - 800px) / 2, 12px)";
    height: @nav-bar-height;
    box-sizing: border-box;
    z-index: 10;
    display: flex;
    align-items: center;

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;

      .item {
        margin-right: 12px;
      }
    }

    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0;

      .icon {
        font-size: 1rem;
        cursor: pointer;
      }

      .item {
        margin: 0 6px;
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;

      .item {
        margin-left: 12px;
      }
    }

    .timer {
      white-space: nowrap;

      .content {
        margin: 0 4px;
      }
    }
  }

  iframe.content {
    display: block;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    overflow: scroll;
    width: 100%;
    height: calc(100% - @nav-bar-height);
  }
}

.react-draggable {
  .handle {
    cursor: grab;
  }

  &.react-draggable-dragging .handle {
    cursor: grabbing;
  }
}

.ant-btn > .ant-badge {
  color: inherit;

  & > .anticon {
    color: inherit;
  }

  & + span {
    margin-left: 8px;
  }
}

.ant-modal-root {
  div[aria-hidden="true"] {
    display: none;
  }
}

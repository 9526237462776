#not-found {
  margin-top: 48px;
  text-align: center;

  .heading {
    font-size: 18px;
  }

  .hint {
    margin-top: 8px;
    color: gray;
  }

  .logout {
    display: block;
    margin-top: 24px;
  }

  .tc-content {
    margin-top: 24px;
  }
}

@import "./app.less";

@border-radius: 9px;

.notepad-container {
  position: absolute;
  width: 100%;
  height: calc(100% - @nav-bar-height);
  background-color: transparent;
  pointer-events: none;
  z-index: 101;
  overflow: hidden;

  &:has(> .dragging) {
    pointer-events: auto;
  }
}

.notepad-window-container {
  pointer-events: auto;

  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }
}

.notepad-window {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px 2px rgba(148, 149, 150, 0.3);
  background-color: white;
  border-radius: @border-radius;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  pointer-events: auto;

  .header-bar {
    height: 36px;
    line-height: 36px;
    padding: 0 6px 0 12px;
    box-sizing: content-box;
    background-color: #0e256e;
    color: white;
    font-size: 14px;
    border-top-left-radius: @border-radius;
    border-top-right-radius: @border-radius;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    padding: 4px 4px;

    .text {
      font-size: 1rem;
      width: 100%;
      padding: 0 8px;
      border-width: 0;
      border-color: transparent;
      outline: none;
      resize: none;

      &:focus,
      &:hover {
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  .close {
    padding: 6px 6px;
  }
}

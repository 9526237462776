#trial-finished {
  margin-top: 48px;
  text-align: center;

  .heading {
    font-size: 28px;
  }

  .body {
    margin-top: 1.5rem;
  }

  .disclosure {
    margin: 4.5rem auto;
    width: fit-content;
  }
}
